<template>
  <p class="subtitle-2 red--text text-center">
    {{ $t('components.discountMsgComponent', { discount: showDiscountInPercentage }) }}
  </p>
</template>

<script>
import { userDataComputed } from '../store/helper';
export default {
  computed: {
    ...userDataComputed,
    showDiscountInPercentage() {
      return `${this.userData.salesmanDiscountPercentage * 100} %`;
    },
  },
};
</script>
