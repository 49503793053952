<template>
  <v-container class="white px-8 pb-6">
    <v-row>
      <v-col cols="12" class="pt-6">
        <v-row>
          <v-col cols="12" sm="3">
            <h2 class="text mb-2">{{ $t('components.deliveryMethodSummary.deliveryCountry') }}</h2>
            <v-autocomplete
              solo
              outlined
              v-model="countryCode"
              :items="mappedCountries"
              :hide-details="true"
              dense
              :label="deliveryCountryCode"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row v-if="foreignCountry">
          <v-col cols="12">
            <p class="text-grey">
              {{ $t('components.deliveryMethodSummary.foreignDeliveryInfo') }}
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8">
        <select-delivery-method ref="selectDeliveryMethod"></select-delivery-method>
      </v-col>
      <v-col cols="12" md="4" class="text-right">
        <order-amount-details :showDeliveryDetails="true"></order-amount-details>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <outlined-button :onClick="prev">{{ $t('helpers.goBack') }}</outlined-button>
      </v-col>
      <v-col>
        <v-spacer></v-spacer>
      </v-col>
      <v-col :class="{ 'text-right': !$vuetify.breakpoint.sm }">
        <primary-button :onClick="next">{{ $t('helpers.continue') }}</primary-button>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import OutlinedButton from '../../../components/buttons/OutlinedButton.vue';
import PrimaryButton from '../../../components/buttons/PrimaryButton.vue';
import OrderAmountDetails from '../../../components/OrderAmountDetails.vue';
import SelectDeliveryMethod from '../../../components/SelectDeliveryMethod.vue';
import { userDataComputed, deliveryComputed, countryCodeComputed } from '../../../store/helper';
import { countriesCodeHandler } from '../../../mixins/countriesCodeHandler';
import { defaultCountryCode } from '../../../static/defaultCountryCode';
import VueI18n from '../../../plugins/i18n';

export default {
  name: 'DeliveryMethodSummary',
  components: { OutlinedButton, PrimaryButton, OrderAmountDetails, SelectDeliveryMethod },
  mixins: [countriesCodeHandler],
  computed: {
    ...userDataComputed,
    ...deliveryComputed,
    ...countryCodeComputed,
  },
  watch: {
    deliveryForeignCountry() {
      return this.deliveryCountryCode !== defaultCountryCode.PL;
    },
    countryCode: function (newVal) {
      this.$store.commit('setDeliveryCountryCode', newVal);
    },
  },
  methods: {
    async handleDeliveryPrice() {
      this.$refs.selectDeliveryMethod.refreshDeliveryOptionsRadio();
      await this.$refs.selectDeliveryMethod.calculateDelivery();
    },
    prev() {
      this.$emit('prev');
    },
    next() {
      if (this.$refs.selectDeliveryMethod.deliveryError) {
        this.$toasted.global.error({
          message: VueI18n.t('components.deliveryMethodSummary.nextFunc'),
        });
        return;
      }

      this.$emit('next');
    },
  },
};
</script>
