<template>
  <v-container>
    <v-row>
      <p>{{ company.address }}</p>
    </v-row>
    <v-row>
      <p>{{ company.zipCode }} {{ company.city }}</p>
    </v-row>
    <v-row>
      <p>{{ getCountryName(company.countryCode) }}</p>
    </v-row>
    <v-row class="mt-4">
      <primary-button :onClick="openModal">{{
        $t('components.changeAddress.edit')
      }}</primary-button>
      <change-address-modal
        :open="modal"
        :title="$t('components.changeAddress.modal.title')"
        :agree="$t('components.changeAddress.modal.agree')"
        :disagree="$t('components.changeAddress.modal.disagree')"
        v-on:decline="decline"
        v-on:accept="accept"
        :loading="loading"
      >
        <v-form v-model="isFormValid" ref="form" class="mt-4">
          <v-row>
            <v-col class="py-0 py-sm-2" cols="12">
              <h2 class="text font-weight-bold text-color">
                {{ $t('helpers.country') }}
              </h2>
              <v-autocomplete
                solo
                outlined
                v-model="editCompany.countryCode"
                :items="mappedCountries"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="py-0 py-sm-2" col="12">
              <h2 class="text font-weight-bold text-color">{{ $t('helpers.address') }}*</h2>
              <v-text-field
                v-model="editCompany.address"
                :rules="validator.requiredRules"
                outlined
                solo
                elevation="0"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0 py-sm-2" col="12" sm="3">
              <h2 class="text font-weight-bold text-color">{{ $t('helpers.zipCode') }}*</h2>
              <v-text-field
                :rules="foreignCountryEditView ? [] : validator.zipCodeRules"
                v-model="editCompany.zipCode"
                outlined
                solo
                elevation="0"
              >
              </v-text-field>
            </v-col>
            <v-col class="py-0 py-sm-2" col="12" sm="9">
              <h2 class="text font-weight-bold text-color">{{ $t('helpers.city') }}*</h2>
              <v-text-field
                :rules="validator.requiredRules"
                v-model="editCompany.city"
                outlined
                solo
                elevation="0"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </change-address-modal>
    </v-row>
  </v-container>
</template>
<script>
import PrimaryButton from './buttons/PrimaryButton.vue';
import ChangeAddressModal from './modals/ChangeAddressModal.vue';
import { validators } from '../validators/Validators';
import CompanyService from '../services/CompanyService';
import { countriesCodeHandler } from '../mixins/countriesCodeHandler';
import { formMixin } from '../mixins/formMixin';

export default {
  components: { PrimaryButton, ChangeAddressModal },
  mixins: [countriesCodeHandler, formMixin],
  name: 'ChangeAddress',
  props: ['company'],
  computed: {
    validator() {
      return validators;
    },
  },
  data() {
    return {
      modal: false,
      loading: false,
      isFormValid: false,
      editCompany: {},
    };
  },
  methods: {
    decline() {
      this.modal = false;
    },
    async accept() {
      this.$refs.form.validate();
      if (this.isFormValid) {
        try {
          this.loading = true;
          await CompanyService.update(this.company._id, {
            ...this.editCompany,
          });
          this.$emit('update');
          this.$toasted.global.success({
            message: this.$i18n.t('components.changeAddress.toasted'),
          });
          this.modal = false;
        } catch (error) {
          console.error(error);
        } finally {
          this.loading = false;
        }
      }
    },
    openModal() {
      this.modal = true;
    },
  },

  created() {
    this.editCompany = {
      address: this.company.address,
      city: this.company.city,
      zipCode: this.company.zipCode,
      countryCode: this.company.countryCode,
    };
  },
};
</script>
<style lang="scss">
@import '../scss/_variables.scss';
.text-color {
  color: $font;
}
</style>
