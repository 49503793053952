<template>
  <div>
    <v-stepper alt-labels v-model="stepper">
      <v-stepper-header>
        <v-stepper-step
          :disabled="isDisabled"
          :complete="stepper > 1"
          step="1"
          @click="stepper > 1 && isDisabled ? (stepper = 1) : null"
          >{{ $t('components.index.summary') }}</v-stepper-step
        >

        <v-divider></v-divider>

        <v-stepper-step
          :disabled="isDisabled"
          :complete="stepper > 2"
          step="2"
          @click="stepper > 2 && isDisabled ? (stepper = 2) : null"
          >{{ $t('components.index.deliveryMethod') }}</v-stepper-step
        >

        <v-divider></v-divider>

        <v-stepper-step
          :disabled="isDisabled"
          :complete="stepper > 3"
          step="3"
          @click="stepper > 3 && isDisabled ? (stepper = 3) : null"
        >
          {{ $t('components.index.address') }}
          {{
            isPickupInPerson
              ? $t('components.index.isPickedInPerson.optionOne')
              : $t('components.index.isPickedInPerson.optionTwo')
          }}</v-stepper-step
        >

        <v-divider></v-divider>

        <v-stepper-step
          :disabled="isDisabled"
          :complete="stepper > 4"
          step="4"
          @click="stepper > 4 && isDisabled ? (stepper = 4) : null"
          >{{ $t('components.index.deliveryDate') }}
          {{
            isPickupInPerson
              ? $t('components.index.isPickedInPerson.optionOne')
              : $t('components.index.isPickedInPerson.optionTwo')
          }}</v-stepper-step
        >

        <v-divider></v-divider>

        <v-stepper-step step="5">{{ $t('components.index.confirmOrder') }}</v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content class="pa-0" step="1">
          <basic-summary v-on:next="next"></basic-summary>
        </v-stepper-content>

        <v-stepper-content class="pa-0" step="2">
          <delivery-method-summary
            ref="deliveryMethod"
            v-on:next="next"
            v-on:prev="prev"
          ></delivery-method-summary>
        </v-stepper-content>

        <v-stepper-content class="pa-0" step="3">
          <address-summary v-on:next="next" v-on:prev="prev"></address-summary>
        </v-stepper-content>

        <v-stepper-content class="pa-0" step="4"
          ><deadline v-on:next="next" v-on:prev="prev"></deadline
        ></v-stepper-content>
        <v-stepper-content class="pa-0" step="5"
          ><finish ref="finish" @resetStepper="resetStepper"></finish>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>
<script>
import AddressSummary from './Address/Address.vue';
import BasicSummary from './BasicSummary/BasicSummary.vue';
import DeliveryMethodSummary from './Delivery/DeliveryMethodSummary.vue';
import Deadline from './Deadline/Deadline.vue';
import Finish from './Finish/Finish.vue';
import { deliveryComputed } from '../../store/helper';
import { deliveryTypes } from '../../static/deliveryTypes';
export default {
  name: 'IndexSummary',
  components: { AddressSummary, Finish, Deadline, BasicSummary, DeliveryMethodSummary },
  data() {
    return {
      stepper: 1,
      orderCreated: false,
    };
  },
  watch: {
    stepper: {
      async handler(val) {
        if (val === 2) {
          await this.$refs.deliveryMethod.handleDeliveryPrice();
        }
        if (val === 5 && !this.orderCreated) {
          this.$refs.finish.createOrder();
          this.orderCreated = true;
        }
      },
      immediate: true,
    },
  },
  methods: {
    next() {
      this.stepper += 1;
    },
    prev() {
      this.stepper -= 1;
    },
    resetStepper() {
      this.stepper = 1;
      this.orderCreated = false;
    },
  },
  computed: {
    isDisabled() {
      return this.stepper !== 5;
    },
    ...deliveryComputed,
    isPickupInPerson() {
      return this.delivery.type === deliveryTypes().PICKUP_IN_PERSON.type;
    },
  },
  created() {
    this.$store.commit('resetDeliveryCountryCode');
  },
};
</script>
