<template>
  <title-wrapper :title="$t('components.manageCompany.title')">
    <v-row v-if="loading" class="mb-6">
      <v-progress-linear indeterminate></v-progress-linear>
    </v-row>
    <div v-else>
      <collapse :title="$t('components.manageCompany.colapseOneTitle')">
        <change-address :company="company" v-on:update="refetch"></change-address>
      </collapse>
      <collapse :title="$t('components.manageCompany.colapseTwoTitle')">
        <change-logo :company="company" v-on:update="refetch"></change-logo
      ></collapse>
    </div>
  </title-wrapper>
</template>
<script>
import Collapse from '../../../components/Collapse.vue';
import ChangeAddress from '../../../components/ChangeAddress.vue';
import TitleWrapper from '../../../components/TitleWrapper.vue';
import CompanyService from '../../../services/CompanyService';
import { userDataComputed } from '../../../store/helper';
import ChangeLogo from '../../../components/ChangeLogo.vue';

export default {
  name: 'ManageCompany',
  components: {
    TitleWrapper,
    Collapse,
    ChangeAddress,
    ChangeLogo,
  },
  data() {
    return {
      loading: false,
      company: {},
    };
  },
  computed: {
    ...userDataComputed,
  },
  methods: {
    async refetch() {
      const companyData = await CompanyService.get(this.userData.companyId);
      this.company = companyData.data;
    },
    async initialize() {
      try {
        this.loading = true;
        const companyData = await CompanyService.get(this.userData.companyId);
        this.loading = false;
        this.company = companyData.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.initialize();
  },
};
</script>
<style lang="scss" scoped></style>
