<template>
  <v-container>
    <p v-if="mode === checkoutModes.WIZARD" class="subtitle-2 text-center">
      {{ $t('components.basicSummary.noCompatybilityControl') }}
    </p>
    <p v-if="cartEmpty" class="subtitle-2 text-center">
      {{ $t('components.basicSummary.addProduct') }}
    </p>
    <p v-if="isOrderBlocked" class="subtitle-2 text-center">
      {{ $t('components.basicSummary.orderBlocked') }}
    </p>
    <discount-msg-component v-if="userData.salesmanDiscountPercentage"></discount-msg-component>
    <v-row>
      <v-col
        ><product-list
          :onBasicSummary="true"
          :items="cart"
          :delivery-price="0"
          ref="productsList"
        ></product-list
      ></v-col>
    </v-row>
    <v-row>
      <v-col
        ><p v-if="createdOffer">
          <b
            >{{ $t('components.basicSummary.offerCreated') }}
            <router-link
              :to="{ name: 'SingleOfferIndex', params: { offerId: createdOffer._id } }"
              >{{ $t('components.basicSummary.goToOffer') }}</router-link
            ></b
          >
        </p>
        <primary-button v-else :disabled="cartEmpty" :onClick="handleOffer">{{
          $t('components.basicSummary.saveAsOffer')
        }}</primary-button></v-col
      >
      <v-spacer></v-spacer>
      <v-col :class="{ 'text-right': !$vuetify.breakpoint.sm }"
        ><primary-button :onClick="next" :disabled="cartEmpty || isOrderBlocked">{{
          $t('helpers.continue')
        }}</primary-button></v-col
      >
    </v-row>
    <confirm-modal
      :open="selectDeliveryMethodModal"
      :title="$t('components.basicSummary.modal.title')"
      :agree="$t('components.basicSummary.modal.agree')"
      :disagree="$t('helpers.cancel')"
      ref="confirmModal"
      color="error-primary"
      v-on:decline="declineCreatingOffer"
      v-on:accept="acceptCreatingOffer"
      :loading="createOfferLoading"
    >
      <select-delivery-method ref="selectDeliveryMethod"></select-delivery-method>
    </confirm-modal>
  </v-container>
</template>
<script>
import PrimaryButton from '../../../components/buttons/PrimaryButton.vue';
import SelectDeliveryMethod from '../../../components/SelectDeliveryMethod.vue';
import ProductList from '../../../components/itemList/ProductList.vue';
import ConfirmModal from '../../../components/modals/ConfirmModal.vue';
import DiscountMsgComponent from '../../../components/DiscountMsgComponent.vue';
import { permissionRolesMixin } from '../../../mixins/permissionRolesMixin';
import { basketDiscountMixin } from '../../../mixins/basketDiscountMixin';

import {
  modeComputed,
  userDataComputed,
  cartComputed,
  deliveryComputed,
  selectedCurrencyObjComputed,
} from '../../../store/helper.js';
import { checkoutModes } from '../../../static/checkoutModes';
import OfferService from '../../../services/OfferService';
import OrderService from '../../../services/OrderService';

export default {
  components: {
    PrimaryButton,
    ProductList,
    ConfirmModal,
    SelectDeliveryMethod,
    DiscountMsgComponent,
  },
  mixins: [permissionRolesMixin, basketDiscountMixin],
  data() {
    return {
      checkoutModes,
      createOfferLoading: false,
      createdOffer: false,
      deliveryServiceName: 'wysyłka',
      selectDeliveryMethodModal: false,
      isOrderBlocked: false,
    };
  },
  methods: {
    declineCreatingOffer() {
      this.selectDeliveryMethodModal = false;
    },
    async acceptCreatingOffer() {
      await this.saveAsOffer();
    },
    next() {
      if (!this.canContinue) {
        return this.$toasted.global.error({
          message: this.$i18n.t('components.basicSummary.nextFunc'),
        });
      }

      if (this.isSysAdmin) {
        this.$refs.productsList.$refs.totalAmountForm.validate();
        if (this.$refs.productsList.amountsFormValid) {
          this.$store.commit('setProducts', this.cart);
          this.$emit('next');
        }
      } else {
        this.$store.commit('setProducts', this.cart);
        this.$emit('next');
      }
    },
    mapItemsToOffer(items) {
      return items.map((item) => ({
        product: item._id,
        quantity: item.quantity,
      }));
    },
    createOfferServices() {
      return this.delivery.price > 0
        ? [
            {
              name: this.deliveryServiceName,
              price: this.delivery.price,
            },
          ]
        : [];
    },
    async saveAsOffer() {
      try {
        this.createOfferLoading = true;
        const body = {
          productsRefs: this.mapItemsToOffer(this.cart),
          deliveryPrice: 0,
          services: this.createOfferServices(),
          currency: this.selectedCurrencyObj,
        };
        const res = await OfferService.create(body);
        this.createdOffer = res.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.selectDeliveryMethodModal = false;
        this.createOfferLoading = false;
      }
    },
    async handleOffer() {
      this.selectDeliveryMethodModal = true;
      await this.$refs.selectDeliveryMethod?.calculateDelivery();
    },

    async checkBlockade() {
      const { data } = await OrderService.checkBlockade(this.cart);
      const isOrderBlocked = data?.isOrderBlocked;
      if (isOrderBlocked) {
        this.isOrderBlocked = true;
      } else {
        this.isOrderBlocked = false;
      }
    },
  },
  computed: {
    ...userDataComputed,
    ...cartComputed,
    ...deliveryComputed,
    ...selectedCurrencyObjComputed,
    cartEmpty() {
      return this.cart.length === 0;
    },
    canContinue() {
      return !this.cart.some((product) => product.quantity === 0);
    },
    ...modeComputed,
  },
  watch: {
    async cart() {
      await this.checkBlockade();
    },
  },
  async created() {
    if (!this.cartEmpty) {
      this.checkForDiscounts();
      await this.checkBlockade();
    }
  },
  async activated() {
    if (!this.cartEmpty) {
      this.checkForDiscounts();
      await this.checkBlockade();
    }
  },
};
</script>
