<template>
  <v-container>
    <v-row>
      <v-col class="px-0 mr-4" sm="3">
        <v-img
          v-if="company.companyLogo"
          contain
          max-height="150"
          max-width="300"
          :src="company.companyLogo"
        ></v-img>
        <p v-else>{{ $t('components.changeLogo.addLogo') }}</p>
      </v-col>

      <v-col sm="8">
        <v-row>
          <v-file-input v-model="inputLogo" show-size truncate-length="50"></v-file-input>
        </v-row>

        <v-row>
          <p class="text-sm grey--text">
            {{ $t('components.changeLogo.instruction') }}
          </p>
          <p class="text-sm grey--text">
            {{ $t('components.changeLogo.info') }}
          </p>
        </v-row>

        <v-row>
          <primary-button :onClick="saveLogo" class="mr-4 mt-2">{{
            $t('components.changeLogo.save')
          }}</primary-button>
          <outlined-button class="mt-2" :onClick="deleteLogo">{{
            $t('components.changeLogo.deleteLogo')
          }}</outlined-button>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import OutlinedButton from './buttons/OutlinedButton.vue';
import PrimaryButton from './buttons/PrimaryButton.vue';
import CompanyService from '../services/CompanyService';
import VueI18n from '../plugins/i18n';

export default {
  name: 'ChangeLogo',
  props: ['company'],
  data() {
    return {
      inputLogo: null,
      loadingInput: false,
      loadingDelete: false,
    };
  },
  components: { PrimaryButton, OutlinedButton },
  methods: {
    async saveLogo() {
      try {
        this.loadingInput = true;
        await CompanyService.updateLogo(this.company._id, this.inputLogo);
        this.$emit('update');
        this.$toasted.global.success({ message: VueI18n.t('components.changeLogo.toasted') });
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingInput = false;
      }
    },
    async deleteLogo() {
      try {
        this.loadingInput = true;
        await CompanyService.deleteLogo(this.company._id);
        this.$emit('update');
        this.$toasted.global.success({ message: VueI18n.t('components.changeLogo.toasted') });
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingInput = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
