<template>
  <div>
    <delivery-options
      @delivery-option-changed="deliveryChange"
      :loading="deliveryLoading"
      ref="deliveryOptions"
      :delivery-price="deliveryPrice"
      :radios="selectedRadio"
      :disableRadios="foreignCountryDelivery"
    ></delivery-options>
  </div>
</template>
<script>
import DeliveryOptions from './DeliveryOptions.vue';
import DeliveryService from '../services/DeliveryService';
import { cartComputed, deliveryComputed, countryCodeComputed } from '../store/helper';
import { deliveryTypes } from '../static/deliveryTypes';
import { isEmpty } from 'lodash';
import { defaultCountryCode } from '../static/defaultCountryCode';
import VueI18n from '../plugins/i18n';
export default {
  name: 'SelectDeliveryMethod',
  components: { DeliveryOptions },
  data() {
    return {
      deliveryLoading: false,
      deliveryError: false,
      deliveryPrice: 0,
    };
  },
  computed: {
    ...cartComputed,
    ...deliveryComputed,
    ...countryCodeComputed,
    deliveryTypes() {
      return deliveryTypes();
    },
    selectedRadio() {
      return isEmpty(this.delivery.name) || this.foreignCountryDelivery
        ? this.deliveryTypes.TO_CUSTOMER_DELIVERY.type
        : this.delivery.type;
    },
    foreignCountryDelivery() {
      return this.deliveryCountryCode !== defaultCountryCode.PL;
    },
  },
  watch: {
    deliveryCountryCode: function () {
      this.calculateDelivery();
    },
  },
  methods: {
    async calculateDelivery() {
      this.deliveryLoading = true;
      try {
        if (!this.foreignCountryDelivery) {
          const { deliveryPrice } = await DeliveryService.getDeliveryPrice(this.cart);
          this.deliveryPrice = deliveryPrice;
        } else {
          this.deliveryPrice = this.deliveryTypes.FOREIGN_DELIVERY.price;
        }
        this.deliveryError = false;
        if (!isEmpty(this.delivery.name)) {
          this.deliveryChange(this.deliveryTypes.TO_CUSTOMER_DELIVERY.type);
        }
      } catch (error) {
        console.error(error);
        this.$toasted.global.error({ message: VueI18n.t('components.selectDeliveryMethod') });
        this.deliveryError = true;
      } finally {
        this.deliveryLoading = false;
      }
    },
    refreshDeliveryOptionsRadio() {
      this.$refs.deliveryOptions.refreshSelectedRadios();
    },
    deliveryChange(value) {
      const deliveryObject = this.getDeliveryObjectFromValue(value, this.deliveryPrice);
      this.$store.commit('setDelivery', deliveryObject);
    },
    getDeliveryObjectFromValue(value, price) {
      const deliveryObject = {
        price: this.deliveryTypes.PICKUP_IN_PERSON.price,
        name: this.deliveryTypes.PICKUP_IN_PERSON.name,
        type: this.deliveryTypes.PICKUP_IN_PERSON.type,
      };

      if (value === this.deliveryTypes.TO_CUSTOMER_DELIVERY.type) {
        deliveryObject.price = price;
        deliveryObject.name = this.deliveryTypes.TO_CUSTOMER_DELIVERY.name;
        deliveryObject.type = this.deliveryTypes.TO_CUSTOMER_DELIVERY.type;
      }

      if (value === this.deliveryTypes.FOREIGN_DELIVERY.type) {
        deliveryObject.price = this.deliveryTypes.FOREIGN_DELIVERY.price;
        deliveryObject.name = this.deliveryTypes.FOREIGN_DELIVERY.name;
        deliveryObject.type = this.deliveryTypes.FOREIGN_DELIVERY.type;
      }
      return deliveryObject;
    },
  },
  async mounted() {
    await this.calculateDelivery();
  },
};
</script>
