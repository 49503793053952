<template>
  <div>
    <v-form v-model="isFormValid" ref="form">
      <v-row>
        <v-col>
          <v-card
            elevation="0"
            class="pa-8 text-center flex"
            height="200px"
            v-if="foreignDelivery(deliveryCountryCode)"
          >
            <h1>{{ $t('components.deadline.individualDelivery') }}</h1>
          </v-card>
          <v-date-picker
            v-else
            v-model="date"
            full-width
            class="mt-4"
            required
            :locale="selectedLanguage"
            firstDayOfWeek="1"
            :min="getMinimumDeliveryDate()"
            :allowed-dates="allowedDates"
          ></v-date-picker>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card elevation="0" class="pa-4">
            <v-checkbox
              v-model="serviceRegulationsAgreement"
              hide-details
              :rules="validators.checkboxRequiredRules"
              class="pa-0 ma-0 mb-3"
            >
              <template slot="label">
                <div class="mr-3">{{ $t('components.deadline.acceptRules') }} *</div>
              </template>
            </v-checkbox>
            <span>
              {{ $t('components.processingPersonalDataLink.titlePartOne') }}
              <span @click="openModal" class="openModal">{{
                $t('components.processingPersonalDataLink.titlePartTwo')
              }}</span
              >.
            </span>
            <p class="text--disabled text-caption mb-0 mt-3 pl-2">
              * - {{ $t('helpers.required') }}
            </p>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <outlined-button :onClick="prev">{{ $t('helpers.goBack') }}</outlined-button>
        </v-col>
        <v-col :class="{ 'text-right': !$vuetify.breakpoint.sm }">
          <primary-button :onClick="makeOrder">{{
            $t('components.deadline.makeOrder')
          }}</primary-button>
        </v-col>
      </v-row>
    </v-form>

    <div class="text-center">
      <v-dialog v-model="dialog" width="800" scrollable>
        <v-card>
          <v-card-text class="pa-0">
            <processing-personal-data :modal="true"></processing-personal-data>
          </v-card-text>
          <v-container>
            <v-row justify="end">
              <v-btn color="primary" class="px-8 py-4 ma-4 v-size--large" @click="closeModal">
                {{ $t('helpers.close') }}
              </v-btn>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import PrimaryButton from '../../../components/buttons/PrimaryButton.vue';
import OutlinedButton from '../../../components/buttons/OutlinedButton.vue';
import { holidayMixin } from '../../../mixins/holidays.js';
import { countriesCodeHandler } from '../../../mixins/countriesCodeHandler.js';
import { validators } from '../../../validators/Validators';
import ProcessingPersonalData from '../../PrivacyPolicy/ProcessingPersonalData.vue';
import { countryCodeComputed, selectedLanguageComputed } from '../../../store/helper';
import { deliveryTypes } from '../../../static/deliveryTypes';
import { formMixin } from '../../../mixins/formMixin';

export default {
  components: {
    PrimaryButton,
    OutlinedButton,
    ProcessingPersonalData,
  },
  name: 'Deadline',
  mixins: [holidayMixin, countriesCodeHandler, formMixin],
  data() {
    return {
      isFormValid: false,
      date: '',
      serviceRegulationsAgreement: false,
      dialog: false,
    };
  },
  methods: {
    openModal() {
      this.dialog = true;
    },
    closeModal() {
      this.dialog = false;
    },
    prev() {
      this.$emit('prev');
    },
    domesticOrder() {
      this.$refs.form.validate();
      if (this.isFormValid && this.date) {
        this.$store.commit('setDeliveryDate', this.date);
        this.$emit('next');
      } else if (!this.date) {
        this.$toasted.global.error({
          message: this.$i18n.t('components.deadline.domesticOrderFunc.toastedOne'),
        });
      } else if (!this.serviceRegulationsAgreement) {
        this.$toasted.global.error({
          message: this.$i18n.t('components.deadline.domesticOrderFunc.toastedTwo'),
        });
      }
    },
    foreignOrder() {
      if (!this.serviceRegulationsAgreement)
        this.$toasted.global.error({
          message: this.$i18n.t('components.deadline.domesticOrderFunc.toastedTwo'),
        });
      else {
        this.$store.commit('setDeliveryDate', deliveryTypes().FOREIGN_DELIVERY.name);
        this.$emit('next');
      }
    },
    makeOrder() {
      if (this.foreignDelivery(this.deliveryCountryCode)) {
        this.foreignOrder();
      } else {
        this.domesticOrder();
      }
    },
  },
  computed: {
    validators() {
      return validators;
    },
    ...selectedLanguageComputed,
    ...countryCodeComputed,
  },
};
</script>
<style lang="scss" scoped>
@import '../../../scss/variables.scss';
.openModal {
  cursor: pointer;
  color: $primary;
}
</style>
