<template>
  <div>
    <div v-if="loading">
      <v-progress-linear indeterminate color="primary"> </v-progress-linear>
      <p class="text-center mt-4">{{ $t('components.finish.pleaseWait') }}</p>
    </div>

    <v-container v-if="!loading && success" class="white d-flex flex-column align-center py-14">
      <v-row
        ><v-col
          ><v-icon color="success" dark size="100px">mdi-checkbox-marked-circle</v-icon></v-col
        >
      </v-row>
      <v-row>
        <v-col
          ><p class="title text-center">
            {{ $t('components.finish.orderPlacedPartOne') }}
            <router-link :to="toSingleOrderByRole(order._id)">{{ order.baselinkerId }}</router-link>
            {{ $t('components.finish.orderPlacedPartTwo') }}
          </p></v-col
        >
      </v-row>
      <v-row>
        <v-col
          ><p class="text text-center">
            {{ $t('components.finish.proformaSent') }}
          </p></v-col
        >
      </v-row>
      <v-row>
        <v-col
          ><primary-button :to="toManageOrdersByRole()">{{
            $t('components.finish.manageOrders')
          }}</primary-button></v-col
        >
      </v-row>
      <v-row>
        <v-col><v-divider></v-divider></v-col>
      </v-row>
      <v-row>
        <v-col
          ><p class="text text-center">
            {{ $t('components.finish.needHelp') }}
          </p></v-col
        >
      </v-row>
    </v-container>
    <v-container v-if="!loading && !success" class="white d-flex flex-column align-center py-14">
      <v-row
        ><v-col><v-icon color="error" dark size="100px">mdi-cancel</v-icon></v-col>
      </v-row>
      <v-row>
        <v-col
          ><p class="title text-center">
            {{ $t('components.finish.orderFailed') }}
          </p></v-col
        >
      </v-row>
      <v-row>
        <v-col
          ><primary-button :onClick="onClickRedo">{{
            $t('components.finish.repeat')
          }}</primary-button></v-col
        >
      </v-row>
      <v-row>
        <v-col
          ><p class="text">
            {{ $t('components.finish.needHelp') }}
          </p></v-col
        >
      </v-row>
    </v-container>
  </div>
</template>
<script>
import PrimaryButton from '../../../components/buttons/PrimaryButton.vue';
import { orderDetailsComputed } from '../../../store/helper.js';
import OrderService from '../../../services/OrderService';
import { permissionRolesMixin } from '../../../mixins/permissionRolesMixin';

export default {
  components: { PrimaryButton },
  mixins: [permissionRolesMixin],
  name: 'Finish',
  data() {
    return {
      loading: true,
      success: '',
      order: '',
    };
  },
  methods: {
    onClickRedo() {
      this.$emit('resetStepper');
      this.loading = true;
      this.success = false;
    },
    async createOrder() {
      this.loading = true;
      try {
        const response = this.isSysAdmin
          ? await OrderService.createOrderByAdmin(this.orderDetails)
          : this.isSalesman
          ? await OrderService.createOrderBySalesman(this.orderDetails)
          : await OrderService.createOrder(this.orderDetails);

        if (response.status === 'success') {
          this.success = true;
          this.$store.commit('resetOrder');
        } else {
          this.success = false;
        }
        this.order = response.data.order;
      } catch (e) {
        this.success = false;
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    toSingleOrderByRole(id) {
      const routeName = this.isSysAdmin
        ? 'SingleOrder'
        : this.isSalesman
        ? 'SalesmanSingleOrder'
        : 'UserSingleOrder';

      return { name: routeName, params: { orderId: id } };
    },
    toManageOrdersByRole() {
      const routeName = this.isSysAdmin
        ? 'OrdersList'
        : this.isSalesman
        ? 'SalesmanOrders'
        : 'UserOrdersList';
      return { name: routeName };
    },
  },
  computed: {
    ...orderDetailsComputed,
  },
};
</script>
<style lang="scss" scoped></style>
