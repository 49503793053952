<template>
  <title-link-wrapper :title="$t('components.addUser-ByUser.title')" :to="{ name: 'ManageUsers' }">
    <v-container class="white pa-8">
      <v-row class="mb-6">
        <h2 class="text font-weight-regular">{{ $t('helpers.userDetails') }}</h2>
      </v-row>
      <v-row> <v-divider class="mb-6"></v-divider></v-row>
      <v-form v-model="isFormValid" ref="form">
        <v-row>
          <v-col class="pa-0" cols="5">
            <h2 class="text mb-3">{{ $t('helpers.firstName') }}*</h2>
            <v-text-field
              v-model="firstName"
              :rules="validator.requiredRules"
              outlined
              solo
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="pa-0" cols="6">
            <h2 class="text mb-3">{{ $t('helpers.lastName') }}*</h2>
            <v-text-field
              v-model="lastName"
              :rules="validator.requiredRules"
              outlined
              solo
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pa-0" cols="12" md="5">
            <h2 class="text mb-3">{{ $t('helpers.phoneNumber') }}*</h2>
            <v-text-field
              v-model="phone"
              :rules="validator.phoneRules"
              outlined
              solo
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pa-0" cols="12" md="5">
            <h2 class="text mb-3">{{ $t('helpers.email') }}*</h2>
            <v-text-field
              v-model="email"
              :rules="validator.emailRules"
              outlined
              solo
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row> <v-divider class="mb-6"></v-divider></v-row>
        <v-row>
          <v-col class="pa-0" cols="12" md="5">
            <h2 class="text mb-3">{{ $t('helpers.typeOfRights') }}*</h2>
            <v-select
              v-model="userRole"
              :items="userRoles"
              :rules="validator.requiredRules"
              label="Outlined style"
              outlined
              solo
            >
              <template v-slot:selection="{ item }">
                {{ $t(`statics.roles.${item}`) }}
              </template>
              <template v-slot:item="{ item }">
                {{ $t(`statics.roles.${item}`) }}
              </template></v-select
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pa-0" cols="12" md="5">
            <outlined-button :onClick="addUser" :loading="loading" outlined>{{
              $t('helpers.addUser')
            }}</outlined-button>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </title-link-wrapper>
</template>
<script>
import OutlinedButton from '../../../components/buttons/OutlinedButton.vue';
import TitleLinkWrapper from '../../../components/TitleLinkWrapper.vue';
import { validators } from '../../../validators/Validators';
import { roles } from '../../../static/roles';
import { userDataComputed } from '../../../store/helper';
import AuthService from '../../../services/AuthService';
import { formMixin } from '../../../mixins/formMixin';

export default {
  name: 'AddUser',
  mixins: [formMixin],
  data() {
    return {
      userRole: roles.EMPLOYEE,
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      isFormValid: false,
      loading: false,
    };
  },
  components: { TitleLinkWrapper, OutlinedButton },
  methods: {
    async addUser() {
      this.$refs.form.validate();

      if (this.isFormValid) {
        const user = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          phone: this.phone,
          companyId: this.userData.companyId,
          role: this.userRole,
          lang: this.userData.lang,
        };

        try {
          this.loading = true;
          const response = await AuthService.registerUser(user);

          this.$router.push({ name: 'UserDetails', params: { userId: response.user._id } });
          this.$toasted.global.success({
            message: this.$i18n.t('components.addUser-ByUser.toasted.addUserSuccess'),
          });
        } catch (error) {
          this.$toasted.global.error({
            message: this.$i18n.t('components.addUser-ByUser.toasted.addUserError'),
          });
          console.error(error);
        } finally {
          this.loading = false;
        }
      }
    },
  },
  computed: {
    userRoles() {
      return [roles.EMPLOYEE, roles.COMPANY_ADMIN];
    },
    validator() {
      return validators;
    },
    ...userDataComputed,
  },
};
</script>
<style lang="scss" scoped></style>
